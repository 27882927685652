<template>
<div>
    <TitleAdmin msg="Clients" routerAdmin="Admin"/>
    <Clients />

</div>
</template>

<script>
import Clients from '@/components/admin/clients/index.vue'
import TitleAdmin from '@/views/constants/titleAdmin.vue'

export default {
    name: 'vClients',
    components: {
        Clients,
        TitleAdmin
    },
    created() {
    },
}
</script>
