<template>
<div>
    <p>Clients</p>
    <div class="row">
        <div class="col">
            <form>
                <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-2 col-form-label">Name2</label>
                    <div class="col-sm-10">
                        <input type="name" class="form-control" v-model="name" />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-2 col-form-label">Redirect</label>
                    <div class="col-sm-10">
                        <input type="redirect" class="form-control" v-model="redirect" />
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary" @click="newClient2">Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div v-for="client in clients" :key="client.id">
                <p>{{ client.id }}</p>
                <p>{{ client.user_id }}</p>
                <p>{{ client.name }}</p>
                <p>{{ client.redirect }}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'vClients',
    data() {
        return {
            clients: '',
            name: '',
            redirect: ''
        }
    },
    created() {
        this.getClients();
    },
    methods: {
        getClients() {
            const url = this.appDomain + "oauth/clients ";
            axios.get(url, )
                .then(res => {
                    this.clients = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        newClient(e) {
            e.preventDefault();
            const url = this.appDomainApi1 + "super-admin/clients";
            const params = {
                name: this.name,
                redirect: this.redirect
            };
            axios.post(url, params)
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.error(err);
                })
        },
        newClient2(e) {
            e.preventDefault();

            const data = {
                name: 'Client Name',
                redirect: 'http://example.com/callback'
            };

            axios.post( this.appDomain + 'oauth/clients', data)
                .then(response => {
                    console.log(response.data);
                })
                .catch(response => {
                    console.log(response);
                    // List errors on response...
                });
        }
    },
}
</script>
